<template>
<div>
  <b-container fluid>
    <b-row class="mb-4" v-if="!permissions.workflow">
      <b-col>
        Access only for RRIC Admins
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.workflow">
      <b-col>
        <b-container class="bg-white">
          <b-row class="mb-4">
            <b-col>
              <menu-workflow :activekey="'journal'" />
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <div class="mb-4" v-for="log in logs" :key="'log-' + log.id">
                <div><router-link  :to="{ name: 'WorkflowArticle', params: { id: log.article.id } }" >[{{log.article.id}}] {{log.article.title}}</router-link></div>
                <div>{{log.username}} {{log.action}} at {{moment(log.created_at).format('HH:mm')}} on {{moment(log.created_at).format('YYYY-MM-DD')}}</div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import moment from 'moment'
import ac from '@/libs/accesscontrol'

import MenuWorkflow from '@/components/workflow/article/Menu.vue'

export default {
  components: {
    MenuWorkflow
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permissions.workflow = ac.can(this.user.acgroups).readAny('workflow').granted
    if (!this.permissions.workflow) {
      this.$router.push({ name: 'Start' })
    }
    this.logs = await this.$Amplify.API.get('cosmos', '/articles/journal')
  },
  data () {
    return {
      logs: [],
      moment: moment,
      permissions: {}
    }
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style>
</style>
